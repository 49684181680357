var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.menus && _vm.menus.length > 0)?_c('div',{staticClass:"footer_menu"},_vm._l((_vm.menus),function(menu){return _c('div',{key:menu.title,staticClass:"menu-item"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.showChildren(menu)}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(menu.title))]),_vm._v(" "),_c('div',{staticClass:"icon"},[_c('img',{class:menu.isOpen ? 'open':'',attrs:{"src":require("assets/img/footer/right-arrow.svg")}})])]),_vm._v(" "),(menu.children && menu.children.length > 0 && menu.isOpen)?_c('div',{staticClass:"item-children"},_vm._l((menu.children),function(item){return _c('div',{key:item.title,staticClass:"c-item",class:[(item.title=='最强称号争霸赛' && _vm.locale=='en')
          || ((item.title=='常见问题' || item.title=='WEEX学堂' || item.title=='媒体报道') && (_vm.locale=='zh-TW' || _vm.locale=='en' || _vm.locale=='ko')
          || (item.title=='Career Opportunity'&&_vm.locale=='en')
          || (item.title=='채용공고'&&_vm.locale=='ko')
          || (item.title=='WEEX Blog'&&_vm.locale=='en')
          || (item.title=='공고알림'&&_vm.locale=='ko'))
          || (item.title=='Brand Ambassador'&&_vm.locale=='en') ? 'hide' : '']},[(item.url=='?fromFooterAppDownload=Y')?_c('div',{staticClass:"a-link",on:{"click":_vm.goDownload}},[_vm._v(_vm._s(item.title))]):(_vm.ckLink(item.url))?_c('a',{staticClass:"a-link",attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.title))]):_c('nuxt-link',{staticClass:"a-link",attrs:{"to":_vm.$i18n.path(item.url)}},[_vm._v(_vm._s(item.title))])],1)}),0):_vm._e()])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }